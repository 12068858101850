import React, { useState } from "react";
import { auth, db } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ref, get } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { Header } from "../components/Header";


const AdminLogin = ({ setUser, setUserType }) => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in:", userCredential.user);
      const user = userCredential.user;
      const userRef = ref(db, `Admin/${user.uid}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        navigate("/admin/home");
        setUser(userData);
        setUserType("admin");
      } else {
        alert("No user data found in the database for this user.");
      }
    } catch (error) {
      const errorMessage = error.message === "Firebase: Error (auth/invalid-credential)."
        ? "Invalid Credentials. Please login using a valid email and password."
        : `Login Error: ${error.message}`;
      alert(errorMessage);
    }
  };

  return (
    <div style={styles.wrapper}>
      <Header />
      <div style={styles.body}>
        <h2>Admin Login</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
          <button type="submit" style={styles.button}>Login</button>
        </form>
        <a href="/landing" style={styles.link2}>Return to landing page</a>
      </div>
    </div>
  );
};

export default AdminLogin;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#fff",
  },
  body: {
    maxWidth: 500,
    width: "100%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    marginTop: 50,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  input: {
    width: "100%",
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "100%",
    padding: 10,
    fontSize: 16,
    borderRadius: 5,
    border: "none",
    backgroundColor: "#333",
    color: "#fff",
    cursor: "pointer",
    boxSizing: "border-box",
  },
  link: {
    marginTop: 20,
    color: "#007BFF",
    cursor: "pointer",
    textDecoration: "underline",
  },
  link2: {
    marginTop: 20,
    color: "black",
    cursor: "pointer",
    textDecoration: "underline",
  },
};
