import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase/config";
import { ref, set, remove, onValue, push, update } from "firebase/database";
import { HeaderWithButtons } from "../components/HeaderWithButtons";

const DAYS_OF_WEEK = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const AdminHome = ({ user, onLogout }) => {
  const [deals, setDeals] = useState([]);
  const [selectedBar, setSelectedBar] = useState(null);
  console.log(selectedBar);
  const [bars, setBars] = useState([]);
  const [newDeal, setNewDeal] = useState({
    description: "",
    day: [],
    time: "",
  });
  const [editingDeal, setEditingDeal] = useState(null);
  const [profile, setProfile] = useState({
    instagramUrl: "",
    websiteUrl: "",
  });

  useEffect(() => {
    const barsRef = ref(db, `Colleges/${user.college}/Bars`);
    onValue(barsRef, (snapshot) => {
      const barsData = snapshot.val();
      if (barsData) {
        setBars(Object.entries(barsData).map(([id, details]) => ({ id, ...details })));
      }
    });
  }, [user]);

  useEffect(() => {
    if (selectedBar) {
      const dealsRef = ref(db, `Colleges/${user.college}/Bars/${selectedBar.id}/deals`);
      onValue(dealsRef, (snapshot) => {
        const fetchedDeals = snapshot.val();
        if (fetchedDeals) {
          setDeals(Object.entries(fetchedDeals).map(([id, details]) => ({ id, ...details })));
        } else {
          setDeals([]);
        }
      });
    }
  }, [selectedBar, user]);

  useEffect(() => {
    if (selectedBar) {
      setProfile({
        instagramUrl: selectedBar.instagramUrl || "",
        websiteUrl: selectedBar.websiteUrl || "",
      });
    } else {
      setProfile({ instagramUrl: "", websiteUrl: "" });
    }
  }, [selectedBar]);
  

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleBarSelect = (e) => {
    const selected = bars.find((bar) => bar.id === e.target.value);
    setSelectedBar(selected || null);
  };
  

  const updateProfile = () => {
    const userRef = ref(db, `Colleges/${user.college}/Bars/${selectedBar.id}`);
    update(userRef, {
      instagramUrl: profile.instagramUrl,
      websiteUrl: profile.websiteUrl,
    })
      .then(() => {
        alert("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
        alert("Failed to update profile. Please try again.");
      });
  };

  const createDeal = useCallback(async (newDeal) => {
    const sortedDays = newDeal.day.sort(
      (a, b) => DAYS_OF_WEEK.indexOf(a) - DAYS_OF_WEEK.indexOf(b)
    );

    const dealsRef = ref(db, `Colleges/${user.college}/Bars/${selectedBar.id}/deals`);

    const newDealRef = push(dealsRef);
    const dealKey = newDealRef.key;

    const deal = {
      ...newDeal,
      day: sortedDays,
      barName: selectedBar.name,
      location: selectedBar.location,
    };

    const dealListRef = ref(db, `Colleges/${user.college}/Deals/${dealKey}`);

    await set(newDealRef, deal);
    await set(dealListRef, deal);
  }, [user, selectedBar]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const dealToUpdate = editingDeal ? editingDeal : newDeal;

    if (type === "checkbox") {
      if (checked) {
        dealToUpdate.day = [...dealToUpdate.day, value];
      } else {
        dealToUpdate.day = dealToUpdate.day.filter((day) => day !== value);
      }
    } else {
      dealToUpdate[name] = value;
    }

    if (editingDeal) {
      setEditingDeal({ ...dealToUpdate });
    } else {
      setNewDeal({ ...dealToUpdate });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dealToSubmit = editingDeal ? editingDeal : newDeal;

    if (dealToSubmit.day.length === 0) {
      alert("Please select at least one day for the deal.");
      return;
    }

    if (!dealToSubmit.description.trim() || !dealToSubmit.time.trim()) {
      alert("Please fill in both description and time.");
      return;
    }

    if (editingDeal) {
      updateDeal(editingDeal);
    } else {
      createDeal(dealToSubmit);
    }

    setNewDeal({ description: "", day: [], time: "" });
    setEditingDeal(null);
  };

  const updateDeal = useCallback(async (deal) => {
    const sortedDays = deal.day.sort((a, b) => DAYS_OF_WEEK.indexOf(a) - DAYS_OF_WEEK.indexOf(b));

    const updatedDeal = {
      ...deal,
      day: sortedDays,
      barName: selectedBar.name,
      location: selectedBar.location,
    };

    const dealRef = ref(db, `Colleges/${user.college}/Bars/${selectedBar.id}/deals/${deal.id}`);
    const dealListRef = ref(db, `Colleges/${user.college}/Deals/${deal.id}`);

    await update(dealRef, updatedDeal);
    await update(dealListRef, updatedDeal);
  }, [user, selectedBar]);

  const startEditing = (deal) => {
    setEditingDeal(deal);
    setNewDeal(deal);
  };

  const cancelEditing = () => {
    setEditingDeal(null);
    setNewDeal({ description: "", day: [], time: "" });
  };

  const deleteDeal = async (id) => {
    if (window.confirm("Are you sure you want to delete this deal?")) {
      const dealListRef = ref(db, `Colleges/${user.college}/Deals/${id}`);
      const dealRef = ref(
        db,
        `Colleges/${user.college}/Bars/${selectedBar.id}/deals/${id}`
      );
      await remove(dealRef);
      await remove(dealListRef);
    }
  };

  return (
    <div style={styles.wrapper}>
      <HeaderWithButtons onLogout={onLogout} />
      <div style={styles.body}>
        <div style={styles.titleContainer}>
          <h1 style={{ ...styles.title, ...headerStyle }}>Admin Home</h1>
        </div>
        <hr style={styles.hr} />
        <h3 style={{ ...headerStyle, margin: 0 }}>Deals</h3>
        <select onChange={handleBarSelect} value={selectedBar ? selectedBar.id : ""} style={styles.select}>
          <option value="" disabled>Select a bar</option>
          {bars.map((bar) => (
            <option key={bar.id} value={bar.id}>{bar.name}</option>
          ))}
        </select>
        {deals.length > 0 ? (
          deals.map((deal) => (
            <div key={deal.id} style={styles.dealWrapper}>
              <div style={styles.dealText}>
                <span>{deal.description}</span>
                <span>Day(s): {deal.day.join(", ")}</span>
                <span>Time: {deal.time}</span>
              </div>
              <div style={styles.buttonGroup}>
                <button
                  onClick={() => editingDeal && editingDeal.id === deal.id ? cancelEditing() : startEditing(deal)}
                  style={styles.editButton}
                >
                  {editingDeal && editingDeal.id === deal.id ? "Cancel" : "Edit"}
                </button>
                <button onClick={() => deleteDeal(deal.id)} style={styles.deleteButton}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No deals available. Add a new deal below!</p>
        )}
        <hr style={styles.hr} />

        <div style={styles.sideBySideContainer}>
          <div style={editingDeal ? styles.formContainerEditing : styles.formContainer}>
            <h3 style={{ ...headerStyle, margin: "5px 0" }}>{editingDeal ? "Edit Deal" : "Create a New Deal"}</h3>
            <form onSubmit={handleSubmit} style={styles.form}>
              <input
                type="text"
                name="description"
                placeholder="Description"
                value={editingDeal ? editingDeal.description : newDeal.description}
                onChange={handleInputChange}
                style={styles.input}
              />
              <input
                type="text"
                name="time"
                placeholder="Time"
                value={editingDeal ? editingDeal.time : newDeal.time}
                onChange={handleInputChange}
                style={styles.input}
              />
              <div style={styles.checkboxGroup}>
                {DAYS_OF_WEEK.map((day) => (
                  <label key={day}>
                    <input
                      type="checkbox"
                      name="day"
                      value={day}
                      checked={(editingDeal ? editingDeal.day : newDeal.day).includes(day)}
                      onChange={handleInputChange}
                    />
                    {day}
                  </label>
                ))}
              </div>
              <div style={styles.formFooter}>
                <button type="submit" style={styles.button}>
                  {editingDeal ? "Update Deal" : "Create Deal"}
                </button>
                {editingDeal && (
                  <button type="button" onClick={cancelEditing} style={styles.cancelButton}>
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </div>

          <div style={styles.profileContainer}>
            <h3 style={{ ...headerStyle, margin: "5px 0" }}>Profile Information</h3>
            <div style={styles.profileSection}>
              <label>Instagram URL</label>
              <input
                type="text"
                name="instagramUrl"
                placeholder="Instagram URL"
                value={profile.instagramUrl}
                onChange={handleProfileChange}
                style={styles.input}
              />
              <label>Website URL</label>
              <input
                type="text"
                name="websiteUrl"
                placeholder="Website URL"
                value={profile.websiteUrl}
                onChange={handleProfileChange}
                style={styles.input}
              />
              <div style={styles.profileFooter}>
                <button onClick={updateProfile} style={styles.button}>
                  Update Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  body: {
    padding: "10px 20px",
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  hr: {
    border: "none",
    height: 1,
    backgroundColor: "black",
    margin: "20px 0",
  },
  dealWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 15,
    border: "1px solid #ccc",
    borderRadius: 8,
    margin: "10px 0",
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  dealText: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  buttonGroup: {
    display: "flex",
    gap: 10,
  },
  editButton: {
    padding: "8px 12px",
    backgroundColor: "#fff",
    color: "#333",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "background-color 0.2s",
    border: "1px solid #333",
  },
  deleteButton: {
    padding: "8px 12px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    transition: "background-color 0.2s",
  },
  cancelButton: {
    padding: 10,
    backgroundColor: "#6c757d",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    width: "100%",
  },
  sideBySideContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    alignItems: "stretch",
  },
  formContainer: {
    flex: 1,
    padding: 10, // Changed from 20 to 10
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  formContainerEditing: {
    flex: 1,
    padding: 10, // Changed from 20 to 10
    border: "3px solid #28a745",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  profileContainer: {
    flex: 1,
    padding: 10, // Changed from 20 to 10
    border: "1px solid #ccc",
    borderRadius: 5,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  input: {
    padding: 10,
    margin: "5px 0",
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  button: {
    padding: 10,
    backgroundColor: "#333",
    color: "#fff",
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    width: "100%",
  },
  checkboxGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
    margin: "10px 0",
  },
  profileSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  formFooter: {
    marginTop: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  profileFooter: {
    marginTop: "auto",
  },
  title: {
    margin: "10px 0",
  },
  select: { 
    padding: "5px", 
    fontSize: "16px",
    margin: "10px 0",
    width: "25%",
  },
};

const headerStyle = {
  color: "#333",
};
