import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ChangeEmail } from "./utilities/ChangeEmail";
import { ChangePassword } from "./utilities/ChangePassword";
import AdminHome from "./Admin/AdminHome";
import AdminLogin from "./Admin/AdminLogin";
import BarLogin from "./Bars/BarLogin";
import BarHome from "./Bars/BarHome";
import BarRegistration from "./Bars/BarRegistration";
import ArtistLogin from "./Artists/ArtistLogin";
import ArtistHome from "./Artists/ArtistHome";
import ArtistRegistration from "./Artists/ArtistRegistration";
import LandingPage from "./LandingPage";

const ROUTES = {
  BAR_LOGIN: "/bar/login",
  BAR_REGISTER: "/bar/register",
  BAR_HOME: "/bar/home",
  ADMIN_LOGIN: "/admin/login",
  ADMIN_HOME: "/home/admin",
  ARTIST_LOGIN: "/artist/login",
  ARTIST_REGISTER: "/artist/register",
  ARTIST_HOME: "/artist/home",
  CHANGE_EMAIL: "/changeEmail",
  CHANGE_PASSWORD: "/changePassword",
  LANDING: "/landing",
};

function App() {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user") || "null"));
  const [userType, setUserType] = useState(() => JSON.parse(localStorage.getItem("userType") || "null"));

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("userType", JSON.stringify(userType));
  }, [user, userType]);

  useEffect(() => {
    if (user) {
      if (userType === "bar" && location.pathname === ROUTES.LANDING) {
        navigate(ROUTES.BAR_HOME, { replace: true });
      } else if (userType === "artist" && location.pathname === ROUTES.LANDING) {
        navigate(ROUTES.ARTIST_HOME, { replace: true });
      } else if (userType === "admin" && location.pathname === ROUTES.LANDING) {
        navigate(ROUTES.ADMIN_HOME, { replace: true });
      }
    }
  }, [user, userType, location, navigate]);

  const handleLogout = useCallback(() => {
    setUser(null);
    setUserType(null);
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    navigate(ROUTES.LANDING);
  }, [navigate]);

  const isBarUser = user && userType === "bar";
  const isArtistUser = user && userType === "artist";
  const isAdmin = user && userType === "admin";

  return (
    <Routes>
      <Route path={ROUTES.ADMIN_LOGIN} element={<AdminLogin setUser={setUser} setUserType={setUserType} />} />
      <Route
        path={ROUTES.ADMIN_HOME}
        element={isAdmin ? <AdminHome user={user} onLogout={handleLogout} /> : <Navigate to={ROUTES.LANDING} replace />}
      />
      <Route path={ROUTES.BAR_LOGIN} element={<BarLogin setUser={setUser} setUserType={setUserType} />} />
      <Route path={ROUTES.BAR_REGISTER} element={<BarRegistration setUser={setUser} />} />
      <Route
        path={ROUTES.BAR_HOME}
        element={isBarUser ? <BarHome user={user} onLogout={handleLogout} /> : <Navigate to={ROUTES.LANDING} replace />}
      />
      <Route path={ROUTES.ARTIST_LOGIN} element={<ArtistLogin setUser={setUser} setUserType={setUserType} />} />
      <Route path={ROUTES.ARTIST_REGISTER} element={<ArtistRegistration setUser={setUser} />} />
      <Route
        path={ROUTES.ARTIST_HOME}
        element={isArtistUser ? <ArtistHome user={user} onLogout={handleLogout} /> : <Navigate to={ROUTES.LANDING} replace />}
      />
      <Route
        path={ROUTES.CHANGE_EMAIL}
        element={user ? <ChangeEmail setUser={setUser} user={user} /> : <Navigate to="*" replace />}
      />
      <Route
        path={ROUTES.CHANGE_PASSWORD}
        element={user ? <ChangePassword user={user} /> : <Navigate to={ROUTES.LANDING} replace />}
      />
      <Route 
        path={ROUTES.LANDING} 
        element={
          <LandingPage 
            isBarUser={isBarUser} 
            isArtistUser={isArtistUser} 
            onBarRedirect={() => navigate(ROUTES.BAR_HOME, { replace: true })}
            onArtistRedirect={() => navigate(ROUTES.ARTIST_HOME, { replace: true })}
          />
        } 
      />
      <Route path="*" element={<Navigate to={ROUTES.LANDING} />} />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
